////////////////////////////////////////////////////
//This is the edit button on the conditioning page//
///////////////////////////////////////////////////

import React, { useState, useRef } from 'react';
import { Form ,Row,Col,Button } from 'react-bootstrap';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { db } from '../DataHandler/firebase';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { getCurrentTime } from '../DataHandler/timeformatter';
import { log } from '../DataHandler/Logger';

const btnShadowNone = { boxShadow: 'none' };

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper  {...props} />
    </Draggable>
  );
} //PaperComponent makes the function draggable

export default function EditConditioning({message}) { //message is the data pulled from the Conditioning page.
  const [open, setOpen] = useState(false); //setopen opens the dialogue, true for open, false for closed.
  const [validated, setValidated] = useState(false); //checks if validated, stays open if validated.
  const connection = db.collection("workorders").doc(message.WOdata).collection("stages").doc("Conditioning").collection("ConditioningProgress"); //the DB connection string
  
  const routineElement = useRef(null);
  const techElement = useRef(null);
  const pressureElement = useRef(null);
  const vacuumElement = useRef(null);
  const kvElement = useRef(null);
  const maElement = useRef(null);
  const arcElement = useRef(null);
  const minutesElement = useRef(null);
  const secondsElement = useRef(null);
  const totaHoursElement = useRef(null);
  const totalMinElement = useRef(null);
  const notesElement = useRef(null); //the references to MUI/Bootstrap elements

  let minsecsplit = message.runtime.split(":");
  let min = minsecsplit[0];
  let sec = minsecsplit[1] //splits the combined string of minsec into 2 elements to fit the format of the run time on dialogue box

  let hourminsplit = message.totaltime.split(":"); //splits hours and mins to fit the format of the  total run time on dialogue box.
  let hour = hourminsplit[0];
  let totalmin = hourminsplit[1];

  
  const handleClickOpen = () => { 
    setValidated(false);
    setOpen(true);
  };

  const handleSave = (e) => {
    e.preventDefault();
    const now = new Date();
    const curr = getCurrentTime();
    const date = curr.slice(0,10);
    const time = curr.slice(12,20);

    const values = { //creates a JSON object of the user inputted values on save.
      routine: routineElement.current.value,
      techname:techElement.current.value,
      pressure:pressureElement.current.value,
      vacuum: vacuumElement.current.value,
      kv:kvElement.current.value,
      ma:maElement.current.value,
      arc:arcElement.current.value,
      runtime:minutesElement.current.value + ":" + secondsElement.current.value,
      totaltime: totaHoursElement.current.value + ":" + totalMinElement.current.value,
      notes:notesElement.current.value
    };
    connection.doc(message.docId).update(values); //pushes values above to DB

    log(now.getTime().toString(), now.toLocaleString(), message.user, message.WOdata, "Conditioning", "Conditioning Progress Edit Row", message.docId+" Editted Row: "+JSON.stringify(values));
    setValidated(true);
    setOpen(false); 
  }

  const handleCancel = () => {
    setOpen(false);
  }

  return ( //what is returned
    <div>
      <Button variant="success" style={btnShadowNone} onClick={handleClickOpen}>Edit</Button>
      <Dialog fullWidth maxWidth="md" PaperComponent={PaperComponent} aria-labelledby="draggable-dialog-title" open={open} onClose={handleCancel}>
        <DialogTitle>Edit Row</DialogTitle>
        <DialogContent>
        <DialogContentText>
          Made a mistake on your row? Edit it here.
        </DialogContentText>
          <Form id="editForm" validated={validated} onSubmit={(e) =>{handleSave(e)}} >
            <Form.Group className="mb-3" controlId="formRoutineElements">
              <Row>
              <Col>
              <Form.Label >Routine Number</Form.Label>
              <Form.Control ref={routineElement} defaultValue={message.routine} required pattern = "\b([1-9]|1[0-5])\b|N/A|Conditioning" maxLength={12} placeholder="1-16, N/A, or Conditioning" type="text" /> 
              </Col>
              <Col>    
              <Form.Label>Technician Name</Form.Label>
              <Form.Control ref={techElement} defaultValue={message.techname} required maxLength={20} placeholder="First Last" type="text"/> 
              </Col>
              <Col>
              <Form.Label>Arc</Form.Label>
              <Form.Control ref={arcElement} defaultValue={message.arc} required maxLength={5} pattern="\d{1,5}" placeholder="ddddd" type="text"/>
              </Col>
              </Row>
              <Row>
              <Col>
              <Form.Label>Running 3L</Form.Label>
              <Form.Control ref={pressureElement} defaultValue={message.pressure} pattern="((\d{1,2})\.(\d{1,2})e-\d)" placeholder="dd.dde-d" required maxLength={8} type="text"/>
              </Col>
              <Col>
              <Form.Label>Vacuum Num</Form.Label>
              <Form.Control ref={vacuumElement} defaultValue={message.vacuum} required maxLength={5} pattern="\d{1,5}" placeholder="ddddd" type="text"/>
              </Col>
              <Col>
              <Form.Label>kV</Form.Label>
              <Form.Control ref={kvElement} defaultValue={message.kv} required maxLength={5} pattern="((\d+)((\.\d{1,2})?))$" placeholder="dd.dd" type="text"/>
              </Col>
              <Col>
              <Form.Label>mA</Form.Label>
              <Form.Control ref={maElement} defaultValue={message.ma} required maxLength={5} pattern="((\d+)((\.\d{1,2})?))" placeholder="dd.dd" type="text"/>
              </Col>
              <Col>
              <Form.Label>Run Time</Form.Label>
              <br></br>
              <Form.Control ref={minutesElement} defaultValue= {min} style={{width:"60px", float:"left", display:"inline"}}required maxLength={3} type="text" placeholder='MM'/>
              <Form.Control ref={secondsElement} defaultValue={sec} style={{width:"60px",float:"left", display:"inline"}} required maxLength={2} type="text" placeholder='SS'/>
              </Col>
              <Col>
              <Form.Label>Total Run Time</Form.Label>
              <br></br>
              <Form.Control ref={totaHoursElement} defaultValue={hour} style={{width:"60px", float:"left", display:"inline"}}required maxLength={3} type="text" placeholder='HH'/>
              <Form.Control ref={totalMinElement} defaultValue ={totalmin} style={{width:"60px",float:"left", display:"inline"}} required maxLength={2} type="text" placeholder='MM'/>
              </Col>
              </Row>
            
              <Form.Label>Notes</Form.Label>
              <Form.Control ref={notesElement} defaultValue= {message.notes} type="text"/>
            </Form.Group>
          </Form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} variant="danger" style={btnShadowNone}>Cancel</Button>
          <Button form="editForm" type="submit" style={btnShadowNone}> Save</Button> {/*button is outside of form, but because form is set here and onCLick is set on line 99 it works */ }
        </DialogActions>
      </Dialog>
    </div>
  );
}