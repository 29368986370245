import React, { useState } from 'react';
import BootstrapButton from 'react-bootstrap/Button';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { db } from '../DataHandler/firebase';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { formatdate, deformatdate } from '../DataHandler/timeformatter';
import { log } from '../DataHandler/Logger';

//Used for the editing button inside the manage downtime button

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper  {...props} />
    </Draggable>
  );
} //this makes the function draggable

export default function EditDialog({message}) {
  const [open, setOpen] = useState(false);
  const [startTime, setStartTime] = useState(deformatdate(message.startstopdata.start));
  const [stopTime, setStopTime] = useState(deformatdate(message.startstopdata.stop));
  const firestore_downtime = db.collection("workorders").doc(message.WOdata).collection("downtimes").doc(message.docId);

  let oldstartvalue = message.startstopdata.start; //used to compare values with other values.
  let oldstopvalue = message.startstopdata.stop;

  const handleClickOpen = () => { // When Dialog open, set the value again just in case the value has changed.
    setStartTime(deformatdate(message.startstopdata.start));
    setStopTime(deformatdate(message.startstopdata.stop))
    oldstartvalue = message.startstopdata.start;
    oldstopvalue = message.startstopdata.stop;
    setOpen(true);
  };

  const handleSave = () => {
    if(startTime === "" || stopTime === "") { alert("Error: please enter valid time."); return; } 
    const d1old = Date.parse(oldstartvalue);
    const d1new = Date.parse(formatdate(startTime));
    const d2old = Date.parse(oldstopvalue);
    const d2new = Date.parse(formatdate(stopTime));
    const time = new Date(); //used to compare old and new values.

    if(d2new - d1new < 0) { alert("Error: STOP TIME cannot be before START TIME"); return; }

    //if new start and stop value the is not same as old start and stop, update values
    if (!(d1old === d1new) || !(d2old === d2new)){
      firestore_downtime.update({
        start: formatdate(startTime),
        stop: formatdate(stopTime),
        totaltime: ((new Date(stopTime) - new Date(startTime))/3600000).toFixed(2)
      });
      log(time.getTime().toString(), time.toLocaleString(), message.user, message.WOdata, message.stage, "DT Edit", message.docId+ ": Editted Start: "+oldstartvalue+" -> "+formatdate(startTime) +" / Stop: "+ oldstopvalue +" -> "+formatdate(stopTime));
    }
    setOpen(false);
  }

  const handleSave_OnlyStart = () => { //if only a start time, start time dialogue appears. on that dialogue save, do this.
    const time = new Date();
    if(startTime === "") { alert("Error: please enter valid time."); return; }
    if(!(Date.parse(oldstartvalue) === Date.parse(formatdate(startTime)))) {
      firestore_downtime.update({start: formatdate(startTime)}); 
      log(time.getTime().toString(), time.toLocaleString(), message.user, message.WOdata, message.stage, "DT Edit", message.docId+ ": Editted Start: "+oldstartvalue+" -> "+formatdate(startTime));
    }
    setOpen(false);
  }

  const handleCancel = () => {
    setOpen(false);
  }

  return (
    <div>
      <BootstrapButton variant="success" style={{boxShadow: 'none'}} onClick={handleClickOpen}>Edit</BootstrapButton>
      <Dialog fullWidth maxWidth="xs" PaperComponent={PaperComponent} aria-labelledby="draggable-dialog-title" open={open} onClose={handleCancel}>
        <DialogTitle>Edit Downtimes</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Made a mistake? Edit the downtime here.
          </DialogContentText> {/*dialogue changes based on if theres a start and stop time */}
          <div>
            <div style={{margin: '10px', marginTop: '20px'}}>
              <label style={{marginRight: '10px'}}>Start:</label>
              <input style={{fontSize:'18px'}} type="datetime-local" value={startTime} onChange= {e=>setStartTime(e.target.value)} id="starttext"/>
            </div>
            { stopTime === "--T:" || stopTime === "--TNaN:" ? "" : 
            <div style={{marginTop: '20px', marginLeft: '10px'}}>
              <label style={{marginRight: '10px'}}>Stop:</label>
              <input style={{fontSize:'18px'}} type="datetime-local" value={stopTime} onChange = {f =>setStopTime((f.target.value))} id="stoptext"/>
            </div>
            }
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} style={{color: 'red'}}>Cancel</Button>
          <Button onClick={stopTime === "--T:" || stopTime === "--TNaN:"? handleSave_OnlyStart : handleSave}>Save</Button> {/* chooses which method to use based on dialogue */}
        </DialogActions>
      </Dialog>
    </div>
  );
}