import React, { useState, useEffect } from 'react';
import { Button, Container } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { db } from '../DataHandler/firebase';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import StageAddRow from '../Components/StageAddRow';
import EditStageProg from '../Components/EditStageProg';

export default function StageProg(){
    const navigate = useNavigate();
    const location = useLocation(); //used for passing data between sister elements.
    const WOdata = location.state?.wo;
    const serial = location.state?.serial;
    const user = location.state?.user;
    const stage = location.state?.stage; //the dats passed.
    const [stageprog, setStageprog] = useState([]);
    const [loading, setLoading] = useState(false);

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // This is the Progress page for all other pages besides conditioning. It works with editStageProg and StageAddRow//
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    useEffect(()=>{
        db.collection("workorders").doc(WOdata).collection("stages").doc(stage).collection(stage + "Progress").onSnapshot((snapshot) => { //chooses right collection to get data from
            const allStages = snapshot.docs.map((doc) => ({id:doc.id, data: doc.data()}))
            setStageprog(allStages);
        });
    }, []);

    return(
        <>
        <Container fluid style={{display: 'inline-flex', flexDirection: 'row', margin: '8px', padding: '0'}}>
            <h1>{WOdata + " (" + serial + ") " + stage + " Progress"}</h1>
            <StageAddRow message={{stage, WOdata, user}}/>
            <Button disabled={loading} onClick={() => { setLoading(false); navigate(-1); setLoading(true); }} variant="danger" style={{fontSize: '1.5vw', margin: '5px', marginLeft: "auto"}}>Back</Button>
        </Container>

        <TableContainer component={Paper} style={{margin: '8px', padding: '0', maxHeight:'85vh', overflowY:'scroll'}}>
            <Table sx={{ minWidth: 50 }} aria-label="simple table">
                <TableHead>
                    <TableRow> {/*the table roiws change based on the stage clicked */}
                        <TableCell width="4%" align="center">Date</TableCell>
                        <TableCell width="5%" align="center">Time</TableCell>
                        <TableCell width="8%" align="center">Tech Name</TableCell>
                        <TableCell width="8%" align="center">Running 3L Pressure (Torr)</TableCell>
                        {["Leak Test"].indexOf(stage) === -1 ? <TableCell width="8%" align="center">Vaccum #</TableCell> : ""}
                        {["Pinch Off", "Profile"].indexOf(stage) === -1 ? <TableCell width="5%" align="center">RunTime</TableCell> : ""}
                        <TableCell width="15%" align="center">Notes</TableCell>
                        <TableCell width="5%" align="center"></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                {stageprog.map((s) => { {/*for each row in the table, do this. Data is populated based on the stage */}
                    return(
                        <TableRow key={s.id}>
                            <TableCell align="center">{s.data.date}</TableCell>
                            <TableCell align="center">{s.data.time}</TableCell>
                            <TableCell align="center">{s.data.techname}</TableCell>
                            <TableCell align="center">{s.data.pressure}</TableCell>
                            {["Leak Test"].indexOf(stage) === -1 ? <TableCell align="center">{s.data.vacuum}</TableCell> : ""}
                            {["Pinch Off", "Profile"].indexOf(stage) === -1 ? <TableCell align="center">{s.data.runtime}</TableCell>: ""}
                            <TableCell align="center">{s.data.notes}</TableCell>
                            <TableCell align="center"><EditStageProg message={{stage,WOdata, docId: s.id, routine: s.data.routine, techname: s.data.techname, pressure: s.data.pressure,vacuum: s.data.vacuum, runtime: s.data.runtime, notes: s.data.notes, user}}/></TableCell> {/* push data to the editstageprog */}
                        </TableRow>
                    )})}
                </TableBody>
            </Table>
        </TableContainer>
        </>
    )
}