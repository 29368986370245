import React, { useState, useRef } from 'react';
import { Form ,Row,Col,Button } from 'react-bootstrap';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { db } from '../DataHandler/firebase';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { getCurrentTime } from '../DataHandler/timeformatter';
import { log } from '../DataHandler/Logger';

///////////////////////////////////////////////////////////////////////////////////////
// This is the add Row button for all other stage progress pages besides conditioning//
//////////////////////////////////////////////////////////////////////////////////////

const btnShadowNone = { boxShadow: 'none' };

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper  {...props} />
    </Draggable>
  );
} //this makes the function draggable

export default function AddRow({message}) {
  const [open, setOpen] = useState(false); //used to open and close, true = open. false = close.
  const [validated, setValidated] = useState(false); //used to keep open if errors in data.
  const connection = db.collection("workorders").doc(message.WOdata).collection("stages").doc(message.stage).collection(message.stage + "Progress");

  const techElement = useRef(null);
  const pressureElement = useRef(null);
  const vacuumElement = useRef(null);
  const minutesElement = useRef(null);
  const secondsElement = useRef(null);
  const notesElement = useRef(null); //references to MUI/Bootstrap components

  const handleClickOpen = () => { 
    setValidated(false);
    setOpen(true);
  };

  const handleSave = (e) => {
    e.preventDefault();
    const curr = getCurrentTime();
    const date = curr.slice(0,10);
    const time = curr.slice(12,20);
    const now = new Date();
    let firestoreid = now.getTime().toString(); 
    const values = ["Leak Test"].indexOf(message.stage) !== -1 ? //terenary statement based on what stage it is.
    {
      date: date, //do we need this?
      time: time,
      techname:techElement.current.value,
      pressure:pressureElement.current.value,
      runtime:minutesElement.current.value + ":" + secondsElement.current.value,
      notes:notesElement.current.value
    } : ["Pinch Off", "Profile"].indexOf(message.stage) !== -1 ?
    {
      date: date,
      time: time,
      techname:techElement.current.value,
      pressure:pressureElement.current.value,
      vacuum: vacuumElement.current.value,
      notes:notesElement.current.value
    } : 
    {
      date: date,
      time: time,
      techname:techElement.current.value,
      pressure:pressureElement.current.value,
      vacuum: vacuumElement.current.value,
      runtime:minutesElement.current.value + ":" + secondsElement.current.value,
      notes:notesElement.current.value
    };
    connection.doc(firestoreid).set(values);

    log(firestoreid, now.toLocaleString(), message.user, message.WOdata, message.stage, "Progress Add Row", firestoreid+" Added Row: "+JSON.stringify(values));
    setValidated(true);
    setOpen(false); 
  }

  const handleCancel = () => {
    setOpen(false);
  }
  
  return ( //dialogue changes based on what stage it is.
    <div>
      <Button style={{fontSize: '1.5vw', margin:'5px', boxShadow: 'none'}} onClick={handleClickOpen}>Add Row</Button>
      <Dialog fullWidth maxWidth="md" PaperComponent={PaperComponent} aria-labelledby="draggable-dialog-title" open={open} onClose={handleCancel}>
        <DialogTitle>Add Row</DialogTitle>
        <DialogContent>
          <Form id="my-form" validated={validated} onSubmit={(e) =>{handleSave(e)}} >
            <Form.Group className="mb-3" controlId="formRoutineElements">
              <Row>
              <Col>    
              <Form.Label>Technician Name</Form.Label>
              <Form.Control ref={techElement} required maxLength={20} placeholder="First Last" type="text"/> 
              </Col>
              </Row>
              <Row>
              <Col>
              <Form.Label>Running 3L pressure</Form.Label>
              <Form.Control ref={pressureElement} pattern="((\d{1,2})\.(\d{1,2})e-\d)" placeholder="dd.dde-d" required maxLength={8} type="text"/>
              </Col>
              {["Leak Test"].indexOf(message.stage) === -1 ?<Col>
              <Form.Label>Vacuum Num</Form.Label>
              <Form.Control ref={vacuumElement} required maxLength={5} pattern="\d{1,5}" placeholder="ddddd" type="text"/>
              </Col>: ""}
              {["Pinch Off", "Profile"].indexOf(message.stage) === -1 ? 
              <Col>
              <Form.Label>Run Time</Form.Label>
              <br></br>
              <Form.Control ref={minutesElement} style={{width:"60px", float:"left", display:"inline"}}required maxLength={3} type="text" placeholder='MM'/>
              <Form.Control ref={secondsElement} style={{width:"60px",float:"left", display:"inline"}} required maxLength={2} type="text" placeholder='SS'/>
              </Col> : ""}
              </Row>
            
              <Form.Label>Notes</Form.Label>
              <Form.Control ref={notesElement} type="text"/>
            </Form.Group>
          </Form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} variant="danger" style={btnShadowNone}>Cancel</Button>
          <Button form='my-form' type="submit" style={btnShadowNone}>Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}