import React, { useState, useRef } from 'react';
import { Form ,Row,Col,Button } from 'react-bootstrap';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { db } from '../DataHandler/firebase';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { getCurrentTime } from '../DataHandler/timeformatter';
import { log } from '../DataHandler/Logger';


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
// We utilize React's reusability here. This is for every edit stage progress dialogue besides conditioning//
/////////////////////////////////////////////////////////////////////////////////////////////////////////////
const btnShadowNone = { boxShadow: 'none' };

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper  {...props} />
    </Draggable>
  );
} //PaperComponent makes the function draggable

export default function EditConditioning({message}) {
  const [open, setOpen] = useState(false); //true sets the dialogue open, false sets the dialogue to close.
  const [validated, setValidated] = useState(false); //keeps dialogue open if theere is errors
  const connection = db.collection("workorders").doc(message.WOdata).collection("stages").doc(message.stage).collection(message.stage + "Progress");
  
  const techElement = useRef(null);
  const pressureElement = useRef(null);
  const minutesElement = useRef(null);
  const vacuumElement = useRef(null);
  const secondsElement = useRef(null);
  const notesElement = useRef(null); //get MUI/Bootstrap components. 

  let minsecsplit = !message.runtime ? 0 : message.runtime.split(":");
  let min = !message.runtime ? 0 : minsecsplit[0]
  let sec = !message.runtime ? 0 : minsecsplit[1] //split min and seconds to match formatting of dialogue box
  
  const handleClickOpen = () => { 
    setValidated(false);
    setOpen(true);
  };

  const handleSave = (e) => {
    e.preventDefault();
    const now = new Date();
    const curr = getCurrentTime();
    const date = curr.slice(0,10);
    const time = curr.slice(12,20);

    const values = ["Leak Test"].indexOf(message.stage) !== -1 ? //grabs values based on the type of stage it is. 
    {
      date: date,
      time: time,
      techname:techElement.current.value,
      pressure:pressureElement.current.value,
      runtime:minutesElement.current.value + ":" + secondsElement.current.value,
      notes:notesElement.current.value
    } : ["Pinch Off", "Profile"].indexOf(message.stage) !== -1 ?
    {
      date: date, /* do we need to update date and time on these? */
      time: time,
      techname:techElement.current.value,
      pressure:pressureElement.current.value,
      vacuum: vacuumElement.current.value,
      notes:notesElement.current.value
    } : 
    {
      date: date,
      time: time,
      techname:techElement.current.value,
      pressure:pressureElement.current.value,
      vacuum: vacuumElement.current.value,
      runtime:minutesElement.current.value + ":" + secondsElement.current.value,
      notes:notesElement.current.value
    };

    connection.doc(message.docId).update(values); //pushes to DB.

    log(now.getTime().toString(), now.toLocaleString(), message.user, message.WOdata, message.stage, "Progress Edit row", message.docId+" Editted Row: "+JSON.stringify(values));
    setValidated(true);
    setOpen(false); 
  }

  const handleCancel = () => {
    setOpen(false);
  }

  return (
    <div>
      <Button variant="success" style={btnShadowNone} onClick={handleClickOpen}>Edit</Button>
      <Dialog fullWidth maxWidth="md" PaperComponent={PaperComponent} aria-labelledby="draggable-dialog-title" open={open} onClose={handleCancel}>
        <DialogTitle>Edit Row</DialogTitle>
        <DialogContent>
        <DialogContentText>
          Made a mistake on your row? Edit it here.
        </DialogContentText> {/* returns the dialogue box based on what stage it is */}
          <Form id="editForm" validated={validated} onSubmit={(e) =>{handleSave(e)}} >
            <Form.Group className="mb-3" controlId="formRoutineElements">
              <Row>
              <Col>    
              <Form.Label>Technician Name</Form.Label>
              <Form.Control ref={techElement} defaultValue={message.techname} required maxLength={20} placeholder="First Last" type="text"/> 
              </Col>
              </Row>
              <Row>
              <Col>
              <Form.Label>Running 3L Pressure</Form.Label>
              <Form.Control ref={pressureElement} defaultValue={message.pressure} pattern="((\d{1,2})\.(\d{1,2})e-\d)" placeholder="dd.dde-d" required maxLength={8} type="text"/>
              </Col>
              {["Leak Test"].indexOf(message.stage) === -1 ?
              <Col>
              <Form.Label>Vacuum Num</Form.Label>
              <Form.Control ref={vacuumElement} defaultValue={message.vacuum} required maxLength={5} pattern="((\d+)((\.\d{1,2})?))$" placeholder="dd.dd" type="text"/>
              </Col>
              :""}
              {["Pinch Off", "Profile"].indexOf(message.stage) === -1 ? 
              <Col>
              <Form.Label>Run Time</Form.Label>
              <br></br>
              <Form.Control ref={minutesElement} defaultValue= {min} style={{width:"60px", float:"left", display:"inline"}}required maxLength={3} type="text" placeholder='MM'/>
              <Form.Control ref={secondsElement} defaultValue={sec} style={{width:"60px",float:"left", display:"inline"}} required maxLength={2} type="text" placeholder='SS'/>
              </Col>:""}
              </Row>
            
              <Form.Label>Notes</Form.Label>
              <Form.Control ref={notesElement} defaultValue= {message.notes} type="text"/>
            </Form.Group>
          </Form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} variant="danger" style={btnShadowNone}>Cancel</Button>
          <Button form="editForm" type="submit" style={btnShadowNone}> Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}