import React from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { Security, LoginCallback } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import Login from './Pages/Login';
import SecureRoute from './DataHandler/SecureRoute';
import Dashboard from './Pages/Dashboard';
import WorkOrder from './Pages/WorkOrder';
import Conditioning from './Pages/Conditioning';
import UserLog from './Pages/UserLog';
import Progress from './Pages/Progress';

const oktaAuth = new OktaAuth({
  issuer: `https://radsource.okta.com/oauth2/default`,
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
  redirectUri: window.location.origin + '/login/callback',
});

function App() {
  const history = useNavigate();
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  return (
    <Container className="d-flex align-items-center justify-content-center" 
      style={{ minHeight: "100vh", minWidth: "100vw", margin: 0, backgroundColor: "rgb(171,202,233)" ,paddingRight: '3vw', paddingLeft:'3vw'}}>
      <div className="w-100">
        <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
          <Routes>
            <Route path="/" element={ <Login/> }/> {/* tje different links */}
            <Route path="/login/callback" element={<LoginCallback/>} />
            <Route path="" element={<SecureRoute/>}>
              <Route path='/dashboard' element={<Dashboard />} exact/>
              <Route path='/wo' element={<WorkOrder />} />
              <Route path='/conditioning' element={<Conditioning/>} />
              <Route path='/userlog' element={<UserLog/>}/>
              <Route path='/progress' element ={<Progress/>}/>
            </Route>
          </Routes>
        </Security>
      </div>
    </Container>
  );
}

// to use UseNavigation <App/> has to be inside of Router
function AppWithRouterAccess() {
  return (<Router><App/></Router>)
}

export default AppWithRouterAccess;
