import React, { useState, useEffect } from 'react';
import { Button, Container, Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { db } from '../DataHandler/firebase';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useOktaAuth } from '@okta/okta-react';
import helppdf from '../Tube Status Operation Manual 1_0_X.pdf'

const btnStyle = { margin: '7px', fontSize: '1.5vw', height: '50px', justifyContent:'center'}
const alertStyle = { margin: '7px', fontSize: '1vw', height: '30px', justifyContent:'center'}

export default function Dashboard(){
    const [lastSyncTime, setLastSyncTime] = useState("loading...");
    const [workOrders, setWorkOrders] = useState([]); //the array of data
    const { oktaAuth } = useOktaAuth();
    const [loading, setLoading] = useState(false);

    const logout = async () => {
        setLoading(true);
        await oktaAuth.signOut('/');
        setLoading(false);
    } //on sign out take them to login page.

    useEffect(()=>{
        db.collection("workorders").onSnapshot((snapshot) => { //used to get workorder data
            const allWorkOrder = snapshot.docs.map((doc) => ({data: doc.data()}))
            setWorkOrders(allWorkOrder);
        });
    }, []);

    useEffect(()=>{
        db.collection("info").doc("sync").onSnapshot((doc) => {//used to grab sync time
            setLastSyncTime(doc.data().lastSyncTime);
        });
    }, []);

    return(
        <>
        <Container fluid style={{display: 'inline-flex', flexDirection: 'row', margin: '8px', padding: '0'}}>
            <h1 align="center">Tube Lab Manufacturing</h1>
            <Container fluid style={{maxWidth: '40vw',display: 'flex', justifyContent: 'flex-end', marginRight: '0'}}>
                <Alert variant='info' style={alertStyle} className="d-flex align-items-center justify-content-center">Last Sync Time: {lastSyncTime}</Alert>
                <Button disabled={loading} onClick={logout} variant="danger" style={btnStyle}>Log Out</Button>
            </Container>
        </Container>
        <TableContainer component={Paper} style={{margin: '8px', padding: '0', maxHeight:'85vh', overflowY:'scroll'}}>
            <Table sx={{ minWidth: 50 }} aria-label="simple table" stickyHeader>
                <TableHead>
                    <TableRow> {/*Table headers*/}
                        <TableCell align="center">Order#</TableCell>
                        <TableCell align="center">Serial Number</TableCell>
                        <TableCell align="center">Production Start Date</TableCell>
                        <TableCell align="center">Assembly</TableCell>
                        <TableCell align="center">Status</TableCell>
                        <TableCell align="center">Tube Build Status</TableCell>
                        <TableCell align="center">Memo</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {workOrders.map((w) => {
                        return(
                            <TableRow key={w.data.workOrderNo}>
                                <TableCell align="center">
                                    <Link to="/wo" state={{id: w.data.id, wo: w.data.workOrderNo, serial: w.data.memo}}>{w.data.workOrderNo}</Link> {/*Uses a link and passes data to sister element, work order */}
                                </TableCell>
                                <TableCell align="center">{w.data.memo}</TableCell>{/* Fills data with table */}
                                <TableCell align="center">{w.data.startDate}</TableCell>
                                <TableCell align="center">{w.data.itemNo}</TableCell>
                                <TableCell align="center">{w.data.status}</TableCell>
                                <TableCell align="center">{w.data.tubeBuildStatus}</TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>
        <a target= "_blank" href={helppdf}>Need Help? Click Here</a>
        </>
    )
}
