import React, { useState } from 'react';
import BootstrapButton from 'react-bootstrap/Button';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { db } from '../DataHandler/firebase';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { formatdate, deformatdate } from '../DataHandler/timeformatter';
import { log } from '../DataHandler/Logger';

///////////////////////////////////////////////////////////
// This is the edit time for the main workorder page     // 
///////////////////////////////////////////////////////////

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper  {...props} />
    </Draggable>
  );
} //this makes the function draggable

export default function EditDialog({message}) { //message is pulled from the workorder page.
  const [open, setOpen] = useState(false); 
  const [startTime, setStartTime] = useState(deformatdate(message.startstopdata.start));
  const [stopTime, setStopTime] = useState(deformatdate(message.startstopdata.stop));

  let oldstartvalue = message.startstopdata.start;  //used to compare and set the value on click.
  let oldstopvalue = message.startstopdata.stop;

  const handleClickOpen = () => { // When Dialog open, set the value again just in case the value has changed.
    setStartTime(deformatdate(message.startstopdata.start));
    setStopTime(deformatdate(message.startstopdata.stop))
    oldstartvalue = message.startstopdata.start;
    oldstopvalue = message.startstopdata.stop;
    setOpen(true);
  };

  const handleSave = () => {
    if(startTime === "" || stopTime === "") { alert("Error: please enter valid time."); return; }
    const d1old = Date.parse(oldstartvalue);
    const d1new = Date.parse(formatdate(startTime));
    const d2old = Date.parse(oldstopvalue);
    const d2new = Date.parse(formatdate(stopTime));
    const time = new Date();

    if(d2new - d1new < 0) { alert("Error: STOP TIME cannot be before START TIME"); return; }
    
    // Edit only when there is change
    if (!(d1old === d1new) || !(d2old === d2new)){
      db.collection("workorders").doc(message.WOdata).collection("stages").doc(message.stage).update({
        start: formatdate(startTime),
        stop: formatdate(stopTime),
        totaltime: ((new Date(stopTime) - new Date(startTime))/3600000).toFixed(2)
      });
      log(time.getTime().toString(), time.toLocaleString(), message.user, message.WOdata, message.stage, "Edit", "Editted Start: "+oldstartvalue+" -> "+formatdate(startTime) +" / Stop: "+ oldstopvalue +" -> "+formatdate(stopTime));
    }
    setOpen(false);
  }

  const handleCancel = () => {
    setOpen(false);
  }

  return ( //dialogue that is returned. 
    <div>
      <BootstrapButton variant="outline-success" style={{boxShadow: 'none'}} onClick={handleClickOpen}>Edit</BootstrapButton>
      <Dialog fullWidth maxWidth="xs" PaperComponent={PaperComponent} aria-labelledby="draggable-dialog-title" open={open} onClose={handleCancel}>
        <DialogTitle>Edit Times</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Made a mistake? Edit the times here.
          </DialogContentText>
          <div>
            <div style={{margin: '10px', marginTop: '20px'}}>
              <label style={{marginRight: '10px'}}>Start:</label>
              <input style={{fontSize:'18px'}} type="datetime-local" value={startTime} onChange= {e=>setStartTime(e.target.value)} id="starttext"/>
            </div>
            <div style={{marginTop: '20px', marginLeft: '10px'}}>
              <label style={{marginRight: '10px'}}>Stop:</label>
              <input style={{fontSize:'18px'}} type="datetime-local" value={stopTime} onChange = {f =>setStopTime((f.target.value))} id="stoptext"/>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} style={{color: 'red'}}>Cancel</Button>
          <Button onClick={handleSave}>Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}