import { db } from './firebase';


//////////////////////////
//USER LOG              //
/////////////////////////
const userlogs = db.collection("userlogs");

export function log(id, time, user, workorder, stage, action, detail){
    userlogs.doc(id).set({
        ms: parseInt(id),
        time: time,
        user: user,
        workorder: workorder,
        stage: stage,
        action: action,
        detail: detail
    });
}

//when log is called in the other pages, it creates a JSON object.=