
import React, { useState, useEffect } from 'react';
import { Button, Container } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { db } from '../DataHandler/firebase';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AddRow from '../Components/AddRow';
import EditConditioning from '../Components/EditConditioning';
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//This is the conditioning progress page. It works with multiple components including AddRow and EditConditioning.//
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export default function Conditioning(){
    const navigate = useNavigate();
    const location = useLocation(); //line 19-20 used for oassing data between sister components. In this case it is the WorkOrder page and Conditioning/.
    const WOdata = location.state?.wo;
    const serial = location.state?.serial;
    const user = location.state?.user; //the variables that are passed
    const [conditioningprog, setConditioningprog] = useState([]); //the array of data
    const [loading, setLoading] = useState(false); 

    useEffect(()=>{
        db.collection("workorders").doc(WOdata).collection("stages").doc("Conditioning").collection("ConditioningProgress").onSnapshot((snapshot) => { //chooses right collection to get data from
            const allStages = snapshot.docs.map((doc) => ({id:doc.id, data: doc.data()}))
            setConditioningprog(allStages);
        });
    }, []);

    return(
        <>
        <Container fluid style={{display: 'inline-flex', flexDirection: 'row', margin: '8px', padding: '0'}}>
            <h1>{WOdata + " (" + serial + ")" + " Conditioning Progress"}</h1> 
            <AddRow message={{WOdata, user}}/>
            <Button disabled={loading} onClick={() => { setLoading(false); navigate(-1); setLoading(true); }} variant="danger" style={{fontSize: '1.5vw', margin: '5px', marginLeft: "auto"}}>Back</Button>
        </Container>

        <TableContainer component={Paper} style={{margin: '8px', padding: '0', maxHeight:'85vh', overflowY:'scroll'}}>
            <Table sx={{ minWidth: 50 }} aria-label="simple table">
                <TableHead>
                    <TableRow> {/*Table Columns*/}
                        <TableCell width="4%" align="center">Date</TableCell>
                        <TableCell width="5%" align="center">Time</TableCell>
                        <TableCell width="4%" align="center">Routine #</TableCell>
                        <TableCell width="8%" align="center">Tech Name</TableCell>
                        <TableCell width="8%" align="center">Running 3L Pressure (Torr)</TableCell>
                        <TableCell width="8%" align="center">Vaccum #</TableCell>
                        <TableCell width="5%" align="center">kV</TableCell>
                        <TableCell width="5%" align="center">mA</TableCell>
                        <TableCell width="5%" align="center">RunTime</TableCell>
                        <TableCell width="5%" align="center">Total Run Time</TableCell>
                        <TableCell width="5%" align="center">Arc</TableCell>
                        <TableCell width="15%" align="center">Notes</TableCell>
                        <TableCell width="5%" align="center"></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                {conditioningprog.map((c) => { //for each row in the table, do this.
                    return(
                        <TableRow key={c.id}> {/* this defines the order that is displayed on the table after Add Row save */}
                            <TableCell align="center">{c.data.date}</TableCell>
                            <TableCell align="center">{c.data.time}</TableCell>
                            <TableCell align="center">{c.data.routine}</TableCell>
                            <TableCell align="center">{c.data.techname}</TableCell>
                            <TableCell align="center">{c.data.pressure}</TableCell>
                            <TableCell align="center">{c.data.vacuum}</TableCell>
                            <TableCell align="center">{c.data.kv}</TableCell>
                            <TableCell align="center">{c.data.ma}</TableCell>
                            <TableCell align="center">{c.data.runtime}</TableCell>
                            <TableCell align="center">{c.data.totaltime}</TableCell>
                            <TableCell align="center">{c.data.arc}</TableCell>
                            <TableCell align="center">{c.data.notes}</TableCell>
                            <TableCell align="center"><EditConditioning message={{WOdata, docId: c.id, routine: c.data.routine, techname: c.data.techname, pressure: c.data.pressure,vacuum: c.data.vacuum, kv: c.data.kv, ma: c.data.ma, arc: c.data.arc, runtime: c.data.runtime, totaltime: c.data.totaltime, notes: c.data.notes, user}}/></TableCell> {/*pushes data to edit conditioning */}
                        </TableRow>
                    )})}
                </TableBody>
            </Table>
        </TableContainer>
        </>
    )
}