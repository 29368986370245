import React, { useState, useEffect, useRef, memo } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Container } from 'react-bootstrap';
import { db } from '../DataHandler/firebase';
import { documentId } from 'firebase/firestore';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';

function isOverflown(element) { //lines 12-110 is the code that makes it display the full text if it is too long
  return (
    element.scrollHeight > element.clientHeight ||
    element.scrollWidth > element.clientWidth
  );
}

const GridCellExpand = memo(
  function GridCellExpand(props) {
    const { width, value } = props;
    const wrapper = useRef(null);
    const cellDiv = useRef(null);
    const cellValue = useRef(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [showFullCell, setShowFullCell] = useState(false);
    const [showPopper, setShowPopper] = useState(false);

    const handleMouseEnter = () => {
      const isCurrentlyOverflown = isOverflown(cellValue.current);
      setShowPopper(isCurrentlyOverflown);
      setAnchorEl(cellDiv.current);
      setShowFullCell(true);
    };

    const handleMouseLeave = () => {
      setShowFullCell(false);
    };

    useEffect(() => {
      if (!showFullCell) {
        return undefined;
      }

      function handleKeyDown(nativeEvent) {
        // IE11, Edge (prior to using Bink?) use 'Esc'
        if (nativeEvent.key === 'Escape' || nativeEvent.key === 'Esc') {
          setShowFullCell(false);
        }
      }

      document.addEventListener('keydown', handleKeyDown);

      return () => {
        document.removeEventListener('keydown', handleKeyDown);
      };
    }, [setShowFullCell, showFullCell]);

    return (
      <Box ref={wrapper} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}
          sx={{alignItems: 'center', lineHeight: '24px', width: 1, height: 1, position: 'relative', display: 'flex', }}>
        <Box ref={cellDiv} sx={{height: 1, width, display: 'block', position: 'absolute', top: 0,}}/>
        <Box ref={cellValue} sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {value}
        </Box>
        {showPopper && (
          <Popper open={showFullCell && anchorEl !== null} anchorEl={anchorEl} style={{ width }}>
            <Paper elevation={1} style={{ minHeight: wrapper.current.offsetHeight - 3 }}>
              <Typography variant="body2" style={{ padding: 8 }}>{value}</Typography>
            </Paper>
          </Popper>
        )}
      </Box>
    );
  });

GridCellExpand.propTypes = {
  value: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
};

function renderCellExpand(params) {
  return (
    <GridCellExpand value={params.value || ''} width={params.colDef.computedWidth} />
  );
}

renderCellExpand.propTypes = {
  /**
   * The column of the row that the current cell belongs to.
   */
  colDef: PropTypes.object.isRequired,
  /**
   * The cell value, but if the column has valueGetter, use getValue.
   */
  value: PropTypes.string.isRequired,
};

export default function UserLog(){
  const [userLog, setUserLog] = useState([]);

  useEffect(()=>{
    db.collection("userlogs").orderBy(documentId()).onSnapshot((snapshot) => { //chooses collection
        const allUserLog = snapshot.docs.map((doc) => {
          const data = doc.data();
          return {id: doc.id, time: data.time, user: data.user, wo: data.workorder, stage: data.stage, action: data.action, detail: data.detail};
        });
        setUserLog(allUserLog);
    });
}, []);

    const columns = [
        { field: 'time', headerName: 'Time', flex: 1.4},
        { field: 'id', headerName: 'ID', flex:1}, /*field is the value used to populate data, headername is the column name. */
        { field: 'user', headerName: 'User', flex:1, renderCell: renderCellExpand},
        { field: 'wo', headerName: 'WO', flex:1 },
        { field: 'stage', headerName: 'Stage', flex:1.4, renderCell: renderCellExpand},
        { field: 'action', headerName: 'Action',flex:1, renderCell: renderCellExpand},
        { field: 'detail', headerName: 'Detail',flex:3, renderCell: renderCellExpand}
        ];

    return (
      <>
      <Container fluid style={{display: 'inline-flex', flexDirection: 'row', margin: '8px', padding: '0'}}>
          <h1 align="center">User Log</h1>
      </Container>
      <Container style={{backgroundColor: 'white', margin: '8px', padding: '0', width: "100vw", height: '85vh'}}>
          <DataGrid maxRowHeight={200} style={{backgroundColor: 'white', padding: '0', width: "93vw"}}
              rows= {userLog}
              columns={columns}
              initialState={{
                sorting: {sortModel: [{ field: 'id', sort: 'desc' }],},
              }}/>
      </Container>    
      </>
    );
}