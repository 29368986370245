export function formatdate(dateformat){
    dateformat = dateformat.toString();
    let year = dateformat.slice(0,4); //formats the date in human readable form
    let month = dateformat.slice(5,7);
    let day = dateformat.slice(8,10);
    let hour= parseInt(dateformat.slice(11,13));
    let min = dateformat.slice(14,16);
    let formatteddate = (month + "/" + day + "/" + year + ", " + hour + ":" + min);
  
    if(hour < 12){
      formatteddate = month + "/" + day + "/" + year + ", " + hour.toString().padStart(2,"0") + ":" + min + " AM"; //if in the morning, add AM
      return formatteddate;                                                                                                                                                           
    } 
    else if(hour === 12) {
      formatteddate = formatteddate + " PM";
      return formatteddate;
    }
    else if(hour > 12){ //if in afternoon, converts from military time to regular time.
      formatteddate = month + "/" + day + "/" + year + ", " + (hour - 12).toString().padStart(2,"0") + ":" + min + " PM";
      return formatteddate;
    }
  }
  
  export function deformatdate(dateformat){ //formats the date in computer readable form
    dateformat = dateformat.toString();
    let year = dateformat.slice(6,10);
    let month = dateformat.slice(0,2);
    let day = dateformat.slice(3,5);
    let hour = dateformat.slice(12,14);
    hour = (parseInt(hour) + (dateformat.slice(18,20) === "PM" && hour != '12'? 12 : 0));
    let min = dateformat.slice(15,17);
    let deformatteddate= (year + "-" + month + "-" + day + "T" + hour.toString().padStart(2,'0') + ":" + min);
  
    return deformatteddate 
  }

  export function getCurrentTime() {
    //time formatter
    const curr = new Date();
    
    let timenow =  curr.toLocaleString("en-US", {timeZone: "America/New_York"}); //gets time in local time with seconds
    const year = curr.getFullYear();
    const month = ((curr.getMonth())+ 1).toString().padStart(2,'0');
    const day = curr.getDate().toString().padStart(2,'0');
    const hours = parseInt(curr.getHours().toLocaleString("en-US", {timeZone: "America/New_York"}));
    const min = curr.getMinutes().toLocaleString("en-US", {timeZone: "America/New_York"}).padStart(2,'0');

    timenow = month + "/" + day + "/" + year  + ", " + hours.toString().padStart(2,"0") + ":" + min;
   
    if(hours < 12){ //if in the morning, add AM 
        timenow = timenow + " AM"; 
    }else if(hours === 12) { 
        timenow = timenow + " PM"; 
    }else if(hours > 12){ //if in afternoon, converts from military time to regular time.
        timenow = month + "/" + day + "/" + year + ", " + (hours - 12).toString().padStart(2,"0") + ":" + min + " PM";
    }

    return timenow;
}