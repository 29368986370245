import React, { useState, useEffect } from 'react';
import BootstrapButton from 'react-bootstrap/Button';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { db } from '../DataHandler/firebase';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { documentId } from 'firebase/firestore'
import { getCurrentTime } from '../DataHandler/timeformatter';
import DTEditDialog from './ReportDTEdit';
import { log } from '../DataHandler/Logger';

/////////////////////////////////////////////////////////////////////////////
//This is for reporting the downtime on the workorder page                ///
/////////////////////////////////////////////////////////////////////////////

export default function DowntimeDialog({message}) { //message is the data passed from the workorder page.
  const [open, setOpen] = useState(false); //for use of opening and closing dialog
  const [downtimes, setDowntimes] = useState([]);//empyy array at first, but use effect populates the array with values from DB. Use state is used to update the return
  const WOdata = message.WOdata;
  const stage = message.stage;
  const user = message.user;
  const firestore_downtimes = db.collection("workorders").doc(WOdata).collection("downtimes"); //DB connection string

  useEffect(()=>{
    firestore_downtimes.where("stage", "==", stage).orderBy(documentId()).onSnapshot((snapshot) => { //Only grabs the downtimes per stage.
      const alldowntimes = snapshot.docs.map((doc) => ({id:doc.id, data: doc.data()})); 
      setDowntimes(alldowntimes);
    });
  }, []);

  async function setCurrentTime(e){ 
    const curr = new Date();
    const timenow = getCurrentTime(); //sets current time

    if(e.target.innerText === "Start"){ 
      const firestoreid = curr.getTime().toString(); //creates a unique firestore document in the DB
      firestore_downtimes.doc(firestoreid).set({ //populates the unique document in the DB.
        start: timenow,
        stop: "",
        totaltime: "",
        stage: stage
      });
      log(curr.getTime().toString(), curr.toLocaleString(), user, WOdata, stage, "DT Start", firestoreid+": Started at "+ timenow);
    }else if(e.target.innerText === "Stop"){
      const firestoreid = downtimes[downtimes.length-1].id; //gets the most recent unique ID
      const start = downtimes[downtimes.length-1].data.start; //gets start time to be used in totaltime.
      firestore_downtimes.doc(firestoreid).update({ //sets value
        stop: timenow,
        totaltime: ((new Date(timenow) - new Date(start))/3600000).toFixed(2)
      });
      log(curr.getTime().toString(), curr.toLocaleString(), user, WOdata, stage, "DT Stop", firestoreid+": Stopped at "+ timenow);
    }
  }
  
  const handleClickOpen = () => { //what do do on open
    setOpen(true);
  };

  const handleClose = () => { //what to do on close
    setOpen(false);
  };

  return ( //what is returned
    <div>
      <BootstrapButton variant="outline-warning" style={{boxShadow: 'none', color: 'purple'}} onClick={handleClickOpen}>Manage DownTime</BootstrapButton>
      <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
        <DialogTitle>Downtimes</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Report Start and Stop Downtime here.
          </DialogContentText>
          <div style={{margin: '8px', display: 'flex'}}>
            {downtimes.length === 0 || !downtimes[downtimes.length-1].data.start || downtimes[downtimes.length-1].data.stop ? 
            <BootstrapButton style={{boxShadow: 'none'}} onClick={(e) => setCurrentTime(e)}>Start</BootstrapButton> :
            <BootstrapButton style={{boxShadow: 'none'}} onClick={(e) => setCurrentTime(e)} variant="danger">Stop</BootstrapButton>
            }
            <h3 style={{marginLeft: 'auto'}}>Total Down Time: {message.totalDowntime} Hour(s)</h3>
          </div>
          <TableContainer component={Paper} style={{margin: '8px', padding: '0', maxHeight:'50vh', overflowY:'scroll', width:'auto'}}>
            <Table aria-label="simple table" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell align="center">#</TableCell>
                  <TableCell align="center">Stage</TableCell>
                  <TableCell align="center">Start</TableCell>
                  <TableCell align="center">Stop</TableCell>
                  <TableCell align="center">Total Time</TableCell>
                  <TableCell align="center">Edit</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {downtimes.map((s, index) => { //for each row in the table, do this.
                  const startstopdata = s.data;
                  const docId = s.id;
                  return(
                  <TableRow key={docId}>
                    <TableCell align="center">{index+1}</TableCell>
                    <TableCell align="center">{s.data.stage}</TableCell>
                    <TableCell align="center">{s.data.start}</TableCell>
                    <TableCell align="center">{s.data.stop}</TableCell>
                    <TableCell align="center"> 
                      {!startstopdata.totaltime ? "0.00" : startstopdata.totaltime} hours
                    </TableCell>
                    <TableCell align="center">
                      <DTEditDialog message={{WOdata, stage, startstopdata, docId, user}}/> 
                    </TableCell>
                  </TableRow>
                  )})}
                </TableBody>
              </Table>
            </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
  }