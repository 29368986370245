import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Container, Alert } from 'react-bootstrap';
import { db } from '../DataHandler/firebase';
import { Link } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DowntimeDialog from '../Components/ReportDT';
import EditDialog from '../Components/ReportEdit';
import GridLoader from "react-spinners/GridLoader";
import { getCurrentTime } from '../DataHandler/timeformatter';
import { log } from '../DataHandler/Logger';
import { useOktaAuth } from '@okta/okta-react';

// Styles
const topStyle ={ margin: '7px', fontSize: '1.5vw', height: '50px', justifyContent:'center'}
const btnShadowNone = { boxShadow: 'none' }

export default function WorkOrder(){
    const navigate = useNavigate();
    const location = useLocation();
    const { authState } = useOktaAuth();
    const user = authState.idToken.claims.name;
    const WOdata = location.state?.wo;
    const serial = location.state?.serial;
    const woId = location.state?.id;
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [fail, setFail] = useState(false);
    const [stages, setStages] = useState([]);//empty array at first, but use effect populates the array with values from DB. Use state is used to update the return.
    const [downtimes, setDowntimes] = useState([]);//empty array at first, but use effect populates the array with values from DB. Use state is used to update the return.
    const firestore_stages = db.collection("workorders").doc(WOdata).collection("stages");
    const firestore_downtimes = db.collection("workorders").doc(WOdata).collection("downtimes");
    const sortvalues = ["Assembly", "Leak Test", "Heat Test", "In Queue (waiting)", "Conditioning", "Pinch Off", "Profile", "Distribution Ready"];

    if(!WOdata) navigate("/dashboard"); // If WO data is empty, go back to dashboard.

    useEffect(()=>{
        firestore_stages.onSnapshot((snapshot) => { //chooses right collection to get data from
            const allStages = snapshot.docs.map((doc) => ({id:doc.id, data: doc.data()}));
            allStages.sort((a,b) => sortvalues.indexOf(a.id) - sortvalues.indexOf(b.id));
            setStages(allStages);
        });
    }, []);

    useEffect(()=>{
        firestore_downtimes.onSnapshot((snapshot) => { //chooses right collection to get data from
          const allDowntimes = snapshot.docs.map((doc) => ({id:doc.id, data: doc.data()}));
          setDowntimes(allDowntimes);
        });
    }, []);

    function setCurrentTime(e, stage, start) {
        if (!stage) return; //if stage is nothing, do nothing
        const timenow = getCurrentTime();
        const time = new Date();

        //looks at value of button if start or stop and updates DB accordingly which then pushes to the UI table from the DB.
        if (e.target.innerText === "Start"){ //if the value of the button is start, update start time in DB to current time which will update it on the UI side
            firestore_stages.doc(stage).update({
                start: timenow
            });
            log(time.getTime().toString(), time.toLocaleString(), user, WOdata, stage, "Start", "Started at "+ timenow);
        }else if (e.target.innerText === "Stop"){
            firestore_stages.doc(stage).update({ //if the value of the button is stop, update stop time and total in DB to current time which will update it on the UI side
                stop: timenow,
                totaltime: ((new Date(timenow) - new Date(start))/3600000).toFixed(2)
            });
            log(time.getTime().toString(), time.toLocaleString(), user, WOdata, stage, "STOP", "Stopped at "+ timenow);
        }
    }
    
    function DeleteData(stage, start, stop){
        const filtereddowntimes = downtimes.filter(filter => {
            return filter.data.stage === stage
        })

        const time = new Date();
        if(start !== ""){
            firestore_stages.doc(stage).update({ start: "", stop: "", totaltime: ""});
            for (let i = 0; i < filtereddowntimes.length; i++){
                firestore_downtimes.doc(filtereddowntimes[i].id).delete();
            }
            log(time.getTime().toString(), time.toLocaleString(), user, WOdata, stage, "RESET", "Cleared Start: "+ start + " / Stop: "+ stop + "and Downtime Data");
        }
        else{
            alert("Nothing to reset!");
        }
    }

// API
async function postToNetsuite(accessToken){
    setLoading(true);setSuccess(false);setFail(false);
    let conditionRT = 0; let heatRT = 0; let leakRT = 0; let pinchRT = 0; let profileRT = 0;
    let conditionProgress = {}; let heatProgress = {}; let leakProgress={}; let pinchProgress = {}; let profileProgress = {};

    const promises = stages.map(async (s) => {
        // Gets Vacuum from progress of each stages
        let documentIds = [];
        await firestore_stages.doc(s.id).collection(s.id+"Progress").get().then(querySnapshot =>{
            querySnapshot.docs.map((doc) => {documentIds.push({id:doc.id, data: doc.data()})});
        });
        documentIds.sort((a,b) => b.id-a.id);
        let lastestProgress = documentIds.length !== 0 ? documentIds[0] : {};

        if(s.id === "Conditioning"){conditionRT = parseFloat(s.data.totaltime); conditionProgress = lastestProgress.data;}
        else if(s.id === "Heat Test"){heatRT = parseFloat(s.data.totaltime); heatProgress = lastestProgress.data;}
        else if(s.id === "Leak Test"){leakRT = parseFloat(s.data.totaltime); leakProgress = lastestProgress.data;}
        else if(s.id === "Pinch Off"){pinchRT = parseFloat(s.data.totaltime); pinchProgress = lastestProgress.data;}
        else if(s.id === "Profile"){profileRT = parseFloat(s.data.totaltime); profileProgress = lastestProgress.data;}
    });

    // waits until promises are done
    await Promise.all(promises);

    let conditionDT = 0; let heatDT = 0; let leakDT = 0; let pinchDT = 0; let profileDT = 0;
    downtimes.map(d => {
        if(d.data.stage === "Conditioning"){ if(d.data.totaltime) conditionDT += parseFloat(d.data.totaltime); }
        else if(d.data.stage === "Heat Test"){ if(d.data.totaltime) heatDT += parseFloat(d.data.totaltime); }
        else if(d.data.stage === "Leak Test"){ if(d.data.totaltime) leakDT += parseFloat(d.data.totaltime); }
        else if(d.data.stage === "Pinch Off"){ if(d.data.totaltime) pinchDT += parseFloat(d.data.totaltime); }
        else if(d.data.stage === "Profile"){ if(d.data.totaltime) profileDT += parseFloat(d.data.totaltime); }
    });

    conditionDT = parseFloat(conditionDT).toFixed(2);
    heatDT = parseFloat(heatDT).toFixed(2);
    leakDT = parseFloat(leakDT).toFixed(2);
    pinchDT = parseFloat(pinchDT).toFixed(2);
    profileDT = parseFloat(profileDT).toFixed(2);

    const conditionTotal = (parseFloat(conditionRT) + parseFloat(conditionDT)).toFixed(2)
    const heatTotal = (parseFloat(heatRT) + parseFloat(heatDT)).toFixed(2)
    const leakTotal = (parseFloat(leakRT) + parseFloat(leakDT)).toFixed(2)
    const pinchTotal = (parseFloat(pinchRT) + parseFloat(pinchDT)).toFixed(2)
    const profileTotal = (parseFloat(profileRT) + parseFloat(profileDT)).toFixed(2)

    if (isNaN(conditionRT) || isNaN(heatRT) || isNaN(leakRT) || isNaN(pinchRT) || isNaN(profileRT)){
        alert("Leak Test, Heat Test, Conditioning, Pinch Off, Profile stages must have a start and stop time");
        setLoading(false);
    }
    else{
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                accessToken: accessToken, 
                id: woId, user: user,
                conditionRunTime: conditionRT, conditionDT: conditionDT, conditionTotal: conditionTotal, conditionProgress: conditionProgress,
                heatRunTime: heatRT, heatDT: heatDT, heatTotal: heatTotal, heatProgress: heatProgress,
                leakRunTime: leakRT, leakDT: leakDT, leakTotal: leakTotal, leakProgress: leakProgress,
                pinchRunTime: pinchRT, pinchDT: pinchDT, pinchTotal: pinchTotal, pinchProgress: pinchProgress,
                profileRunTime: profileRT, profileDT: profileDT, profileTotal: profileTotal, profileProgress: profileProgress
            })
        };

    fetch(process.env.REACT_APP_POST_TO_NETSUITE_LINK, requestOptions)
    //fetch("http://localhost:5001/radsource-tubestatus/us-central1/tubeHTTP/post", requestOptions)
        .then(response => {
            setLoading(false);
            if(response.status === 200){
                setSuccess(true);
                log(new Date().getTime().toString(), new Date().toLocaleString(), user, WOdata, "N/A", "POST", "Sent data to NS: "+ requestOptions.body);
            }else{
                setFail(true); 
                console.log(response);
            };
        })
        .catch(error => {
            console.log(error);
            setLoading(false);
            setFail(true);
        });
    }
}

    return(
        <>
        <Container fluid style={{display: 'inline-flex', flexDirection: 'row', margin: '8px', padding: '0'}}>
            <h1>{WOdata + " (" + serial + ")"}</h1>
            <Container fluid style={{maxWidth: '40vw',display: 'flex', justifyContent: 'flex-end', marginRight: '0'}}>
                {loading ? <Alert variant='info' style={topStyle} className="d-flex align-items-center justify-content-center">Submitting...</Alert> : ""}
                {success ? <Alert variant='success' style={topStyle} className="d-flex align-items-center justify-content-center">Submit Success!</Alert> : ""}
                {fail ? <Alert variant='danger' style={topStyle} className="d-flex align-items-center justify-content-center">Submit Failed!</Alert> : ""}
                <GridLoader color="#4c4c4c" loading={loading}/>
                <Button disabled={loading} style={topStyle} onClick={() => postToNetsuite(authState.accessToken.accessToken) }>Submit</Button>
                <Button disabled={loading} style={topStyle} onClick={() => { setLoading(false); navigate(-1); setLoading(true); }} variant="danger">Go Back</Button>
            </Container>
        </Container>
        <TableContainer component={Paper} style={{margin: '8px', padding: '0', maxHeight:'85vh', overflowY:'scroll'}}>
            <Table sx={{ minWidth: 50 }} aria-label="simple table" stickyHeader>
                <TableHead>
                    <TableRow> {/*Run time changed to Cycle Time. This is the headers.*/}
                        <TableCell align="center">Stage</TableCell>
                        <TableCell align="center">Timer</TableCell>
                        <TableCell align="center">Start Time</TableCell>
                        <TableCell align="center">Stop Time</TableCell>
                        <TableCell align="center">Cycle Time</TableCell> 
                        <TableCell align="center">Down Time</TableCell>
                        <TableCell align="center">Total Time</TableCell>
                        <TableCell align="center">Downtime</TableCell> 
                        <TableCell align="center">Clear Data</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {stages.map((s) => { //for each row in the table, do this.
                        const stage = s.id;
                        const startstopdata = s.data;
                        const start = startstopdata.start // assigned to const variable for passing down as a parameter to EditDialog
                        const stop = startstopdata.stop;
                        const runtime = parseFloat(!startstopdata.totaltime ? "0.00" : startstopdata.totaltime).toFixed(2);
                        let totalDowntime = 0;
                        downtimes.filter(d => d.data.stage === stage)
                            .forEach(d => {if(d.data.totaltime) totalDowntime += parseFloat(d.data.totaltime)});
                        totalDowntime = parseFloat(totalDowntime).toFixed(2);
                        return(
                        <TableRow key={stage}>
                            <TableCell align="center">
                                {stage === "Conditioning" ? <Link to="/conditioning" state={{wo:WOdata, serial: serial, user: user}}>{stage}</Link> : stage === "Distribution Ready" ? <div style={{fontWeight: "800"}}>{stage}</div>
                                 : stage === "Assembly" ? <div style={{fontWeight: "800"}}>{stage}</div> : stage === "In Queue (waiting)" ? <div style={{fontWeight: "800"}}>{stage}</div> 
                                 : <Link to="/progress" state={{wo:WOdata, serial: serial, user: user, stage: stage}}>{stage}</Link>} {/* depending on what stage it is, give it a link or not */}
                            </TableCell>
                            <TableCell align="center">
                                {!start ? <Button variant="outline-primary" style={btnShadowNone} onClick={(e) => setCurrentTime(e, s.id)}>Start</Button> : //terenary statements that choose which one to show depending on if start and stop time is null
                                !stop ? <Button variant="outline-danger" style={btnShadowNone} onClick={(e) => setCurrentTime(e, s.id, start)}>Stop</Button> :
                                <EditDialog message={{WOdata, stage, startstopdata, user}}/> 
                                }
                            </TableCell>
                            <TableCell align="center"><div style={{fontWeight: "800"}}>{start}</div></TableCell>
                            <TableCell align="center"><div style={{fontWeight: "800"}}>{stop}</div></TableCell>
                            <TableCell align="center">{runtime} hr(s)</TableCell>
                            <TableCell align="center">{totalDowntime} hr(s)</TableCell>
                            <TableCell align="center">{(parseFloat(runtime) + parseFloat(totalDowntime)).toFixed(2)} hr(s)</TableCell>
                            <TableCell align="center"><DowntimeDialog message={{WOdata, stage, startstopdata, totalDowntime, user}}/></TableCell>
                            <TableCell align= "center">
                                <Button variant="danger" style={btnShadowNone} onClick={() => DeleteData(s.id, start, stop)}>Reset</Button>
                            </TableCell>
                        </TableRow>
                        )})}
                </TableBody>
            </Table>
        </TableContainer>
        </>
    )
}

