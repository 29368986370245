import React from 'react';
import { Button, Card, Container } from 'react-bootstrap';
import Image from "react-bootstrap/Image";
import { useOktaAuth } from '@okta/okta-react';
import { Link } from 'react-router-dom';
import logo from '../Images/radsource_icon.png';

export default function Login(){
    const { oktaAuth, authState } = useOktaAuth();

    const login = async () => {
      await oktaAuth.signInWithRedirect({originalUri: "/dashboard"});
    }

    if(!authState) {
        return <Container>Loading...</Container>;
    }else if(!authState.isAuthenticated) {
      return (
        <Container style={{maxWidth: '70vw'}} >
          <Card>
            <Card.Title style={{margin:'0', fontSize: '3vw'}}>
              <Image src={logo} alt="Logo" style={{width: '15vw', paddingLeft: '1vw', paddingTop: '1vh'}}/>
              Tube Status Web App Login
            </Card.Title>
            <Card.Body>
              <Button className="w-100" onClick={() => {login();}} style={{fontSize: '3vw'}}>Log In</Button>
            </Card.Body>
          </Card>
          <p style={{ color: "grey",fontSize:'18px', fontWeight: 'bold',position: 'absolute', bottom: '0', right: '2%'}}>V 1.0.1 </p>
        </Container>
      );
    }else{
      return (
        <>
          <Container style={{maxWidth: '70vw'}} >
          <Card>
            <Card.Title style={{margin:'0', fontSize: '3vw'}}>
              <Image src={logo} alt="Logo" style={{width: '15vw', paddingLeft: '1vw', paddingTop: '1vh'}}/>
              Tube Status Web App Login
            </Card.Title>
            <Card.Body>
              <Link to="/dashboard">
                <Button className="w-100" style={{fontSize: '3vw'}}>To Dashboard</Button>
              </Link>
            </Card.Body>
          </Card>
          </Container>
          <p style={{ color: "grey",fontSize:'18px', fontWeight: 'bold',position: 'absolute', bottom: '0', right: '2%'}}>V 1.0.1 </p>
        </>
      );
    }
}