import React, { useState, useRef } from 'react';
import { Form ,Row,Col,Button } from 'react-bootstrap';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { db } from '../DataHandler/firebase';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { getCurrentTime } from '../DataHandler/timeformatter';
import { log } from '../DataHandler/Logger';


///////////////////////////////////////////////////////////////////
// This is the Conditioning Progress Page Add Button             //
///////////////////////////////////////////////////////////////////
const btnShadowNone = { boxShadow: 'none' };

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper  {...props} />
    </Draggable>
  );
} //PaperComponent makes it draggable

export default function AddRow({message}) {
  const [open, setOpen] = useState(false); //used to open and close the dialogue false = close, true = open.
  const [validated, setValidated] = useState(false);  //only closes if validated, otherwise stays open with the errors.
  const connection = db.collection("workorders").doc(message.WOdata).collection("stages").doc("Conditioning").collection("ConditioningProgress") //grab the DB string
  
  const routineElement = useRef(null);
  const techElement = useRef(null);
  const pressureElement = useRef(null);
  const vacuumElement = useRef(null);
  const kvElement = useRef(null);
  const maElement = useRef(null);
  const arcElement = useRef(null);
  const minutesElement = useRef(null);
  const secondsElement = useRef(null);
  const totaHoursElement = useRef(null);
  const totalMinElement = useRef(null);
  const notesElement = useRef(null); //this is how the MUI/Bootstrap elements are given an ID.

  const handleClickOpen = () => { 
    setValidated(false); 
    setOpen(true);
  }; //when open, set to true, set validated to false.

  const handleSave = (e) => {
    e.preventDefault(); //to prevent closing if there is errors.
    const curr = getCurrentTime();
    const date = curr.slice(0,10);
    const time = curr.slice(12,20);
    const now = new Date();
    let firestoreid = now.getTime().toString();
    const values = {
      date: date,
      time: time,
      routine: routineElement.current.value,
      techname:techElement.current.value,
      pressure:pressureElement.current.value,
      vacuum: vacuumElement.current.value,
      kv:kvElement.current.value,
      ma:maElement.current.value,
      arc:arcElement.current.value,
      runtime:minutesElement.current.value + ":" + secondsElement.current.value,
      totaltime: totaHoursElement.current.value + ":" + totalMinElement.current.value,
      notes:notesElement.current.value
    };
    connection.doc(firestoreid).set(values);

    log(firestoreid, now.toLocaleString(), message.user, message.WOdata, "Conditioning", "Conditioning Progress Add Row", firestoreid+" Added Row: "+JSON.stringify(values));
    setValidated(true);
    setOpen(false); 
  }

  const handleCancel = () => {
    setOpen(false);
  }
  
  return ( //returns the AddRow, Form is the Dialogue Box.
    <div>
      <Button style={{fontSize: '1.5vw', margin:'5px', boxShadow: 'none'}} onClick={handleClickOpen}>Add Row</Button>
      <Dialog fullWidth maxWidth="md" PaperComponent={PaperComponent} aria-labelledby="draggable-dialog-title" open={open} onClose={handleCancel}>
        <DialogTitle>Add Row</DialogTitle>
        <DialogContent>
          <Form id="my-form" validated={validated} onSubmit={(e) =>{handleSave(e)}} > {/*on submit, call handleSave */}
            <Form.Group className="mb-3" controlId="formRoutineElements">
              <Row>
              <Col>
              <Form.Label >Routine Number</Form.Label>
              <Form.Control ref={routineElement} required pattern = "\b([1-9]|1[0-5])\b|N/A|Conditioning" maxLength={12} placeholder="1-16, N/A, or Conditioning" type="text" /> {/*could possibly use JS validation as well */}
              </Col>
              <Col>    
              <Form.Label>Technician Name</Form.Label>
              <Form.Control ref={techElement} required maxLength={20} placeholder="First Last" type="text"/> 
              </Col>
              <Col>
              <Form.Label>Arc</Form.Label>
              <Form.Control ref={arcElement} required maxLength={5} pattern="\d{1,5}" placeholder="ddddd" type="text"/>
              </Col>
              </Row>
              <Row>
              <Col>
              <Form.Label>Running 3L</Form.Label>
              <Form.Control ref={pressureElement} pattern="((\d{1,2})\.(\d{1,2})e-\d)" placeholder="dd.dde-d" required maxLength={8} type="text"/>
              </Col>
              <Col>
              <Form.Label>Resting Vacuum</Form.Label>
              <Form.Control ref={vacuumElement} required maxLength={5} pattern="\d{1,5}" placeholder="ddddd" type="text"/>
              </Col>
              <Col>
              <Form.Label>kV</Form.Label>
              <Form.Control ref={kvElement} required maxLength={5} pattern="((\d+)((\.\d{1,2})?))$" placeholder="dd.dd" type="text"/>
              </Col>
              <Col>
              <Form.Label>mA</Form.Label>
              <Form.Control ref={maElement} required maxLength={5} pattern="((\d+)((\.\d{1,2})?))" placeholder="dd.dd" type="text"/>
              </Col>
              <Col>
              <Form.Label>Run Time</Form.Label>
              <br></br>
              <Form.Control ref={minutesElement} style={{width:"60px", float:"left", display:"inline"}}required maxLength={3} type="text" placeholder='MM'/>
              <Form.Control ref={secondsElement} style={{width:"60px",float:"left", display:"inline"}} required maxLength={2} type="text" placeholder='SS'/>
              </Col>
              <Col>
              <Form.Label>Total Run Time</Form.Label>
              <br></br>
              <Form.Control ref={totaHoursElement} style={{width:"60px", float:"left", display:"inline"}}required maxLength={3} type="text" placeholder='HH'/>
              <Form.Control ref={totalMinElement} style={{width:"60px",float:"left", display:"inline"}} required maxLength={2} type="text" placeholder='MM'/>
              </Col>
              </Row>
            
              <Form.Label>Notes</Form.Label>
              <Form.Control ref={notesElement} type="text"/>
            </Form.Group>
          </Form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} variant="danger" style={btnShadowNone}>Cancel</Button>
          <Button form='my-form' type="submit" style={btnShadowNone}>Save</Button> {/*the save button is outside the form but still calls handle save due to being type submit, form= my form, and onClick on line 84*/}
        </DialogActions>
      </Dialog>
    </div>
  );
}